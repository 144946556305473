var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "id": "kuisioner",
      "pos": "relative",
      "w": "100%",
      "mx": "auto",
      "bg": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['0.5rem', '1.5rem'],
      "min-height": ['calc(100vh - 62px)', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Formulir Gizi',
        isCurrent: true
      }]
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['100%', '600px'],
      "mx": "auto",
      "bg": "#FFF",
      "p": ['1rem', '0px']
    }
  }, [_c('Steps', {
    attrs: {
      "current": 1,
      "total": 6
    }
  }), _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "590px",
      "mx": "auto",
      "margin-top": ['16px', '24px']
    }
  }, [_c('c-box', {
    attrs: {
      "text-align": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "text-transform": "uppercase",
      "font-weight": "500",
      "font-size": ['16px', '18px'],
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Formulir Gizi (1/6) ")]), _c('c-heading', {
    attrs: {
      "font-weight": "700",
      "font-size": ['18px', '28px'],
      "line-height": ['27px', '42px'],
      "color": "black.900"
    }
  }, [_vm._v(" Identitas & Data Umum ")])], 1), _c('form', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "marginTop": "24px"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('FormAccordionList', {
    attrs: {
      "allow-toggle": ""
    }
  }, [_c('FormAccordionListItem', {
    attrs: {
      "label": "Data Diri",
      "is-error": !_vm.isSectionDataDiriValid
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "text",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.firstName.$invalid,
      "label": "Nama Depan",
      "placeholder": "Masukkan nama depan"
    },
    model: {
      value: _vm.firstName,
      callback: function callback($$v) {
        _vm.firstName = $$v;
      },
      expression: "firstName"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "text",
      "is-disabled": !_vm.isEditable,
      "label": "Nama Tengah",
      "placeholder": "Masukkan nama tengah"
    },
    model: {
      value: _vm.middleName,
      callback: function callback($$v) {
        _vm.middleName = $$v;
      },
      expression: "middleName"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "text",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.lastName.$invalid,
      "label": "Nama Belakang",
      "placeholder": "Masukkan nama belakang"
    },
    model: {
      value: _vm.lastName,
      callback: function callback($$v) {
        _vm.lastName = $$v;
      },
      expression: "lastName"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.gender.$invalid,
      "label": "Jenis Kelamin",
      "placeholder": "Pilih Jenis Kelamin",
      "options": [{
        value: 'male',
        label: 'Laki-laki'
      }, {
        value: 'female',
        label: 'Perempuan'
      }]
    },
    model: {
      value: _vm.gender,
      callback: function callback($$v) {
        _vm.gender = $$v;
      },
      expression: "gender"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "value": _vm.age,
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.age.$invalid,
      "label": "Usia",
      "placeholder": "Masukkan usia",
      "is-read-only": ""
    }
  }), _c('FormInput', {
    attrs: {
      "type": "date",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.birthDate.$invalid,
      "label": "Tanggal Lahir",
      "placeholder": "Masukkan tanggal lahir"
    },
    model: {
      value: _vm.birthDate,
      callback: function callback($$v) {
        _vm.birthDate = $$v;
      },
      expression: "birthDate"
    }
  })], 1), _c('FormAccordionListItem', {
    attrs: {
      "label": "Alamat",
      "is-error": !_vm.isSectionAlamatValid
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "text",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.address.$invalid,
      "label": "Alamat Rumah",
      "placeholder": "Masukkan alamat"
    },
    model: {
      value: _vm.address,
      callback: function callback($$v) {
        _vm.address = $$v;
      },
      expression: "address"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.country.$invalid,
      "label": "Negara",
      "placeholder": "Pilih Negara",
      "options": _vm.countries
    },
    on: {
      "trigger-change": _vm.handleChangeCountry
    },
    model: {
      value: _vm.country,
      callback: function callback($$v) {
        _vm.country = $$v;
      },
      expression: "country"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.province.$invalid,
      "label": "Provinsi",
      "placeholder": "Pilih Provinsi",
      "options": _vm.states
    },
    on: {
      "trigger-change": _vm.handleChangeProvince
    },
    model: {
      value: _vm.province,
      callback: function callback($$v) {
        _vm.province = $$v;
      },
      expression: "province"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.city.$invalid,
      "label": "Kota",
      "placeholder": "Pilih Kota",
      "options": _vm.cities
    },
    model: {
      value: _vm.city,
      callback: function callback($$v) {
        _vm.city = $$v;
      },
      expression: "city"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.zipCode.$invalid,
      "label": "Kode Pos",
      "placeholder": "Masukkan Kode Pos"
    },
    model: {
      value: _vm.zipCode,
      callback: function callback($$v) {
        _vm.zipCode = $$v;
      },
      expression: "zipCode"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "tel",
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.phone.$invalid,
      "label": "Nomor HP (available whatsapp)",
      "placeholder": "Masukkan nomor telepon"
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  })], 1)], 1), _c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.mainTarget.$invalid,
      "label": "Apa tujuan utama kamu?",
      "placeholder": "Pilih Jawaban",
      "options": _vm.OPTIONS_MAIN_TARGET
    },
    model: {
      value: _vm.mainTarget,
      callback: function callback($$v) {
        _vm.mainTarget = $$v;
      },
      expression: "mainTarget"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.expectedDiet.$invalid,
      "label": "Tipikal diet yang kamu harapkan",
      "placeholder": "Pilih Jawaban",
      "options": _vm.OPTIONS_EXPECTED_DIET
    },
    model: {
      value: _vm.expectedDiet,
      callback: function callback($$v) {
        _vm.expectedDiet = $$v;
      },
      expression: "expectedDiet"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.hasScales.$invalid,
      "label": "Apakah kamu memiliki timbangan berat badan di rumah?",
      "placeholder": "Pilih Jawaban",
      "options": _vm.OPTIONS_BOOLEAN
    },
    model: {
      value: _vm.hasScales,
      callback: function callback($$v) {
        _vm.hasScales = $$v;
      },
      expression: "hasScales"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-disabled": !_vm.isEditable,
      "is-required": "",
      "is-invalid": _vm.$v.currentCondition.$invalid,
      "label": "Mana yang paling mendeskripsikan kondisi Klien saat ini?",
      "placeholder": "Pilih Jawaban",
      "options": _vm.OPTIONS_CURRENT_CONDITION
    },
    model: {
      value: _vm.currentCondition,
      callback: function callback($$v) {
        _vm.currentCondition = $$v;
      },
      expression: "currentCondition"
    }
  }), _c('c-flex', {
    attrs: {
      "margin-top": "16px",
      "justify-content": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "type": "submit",
      "w": "100%",
      "h": "48px",
      "max-w": "500px",
      "border-radius": "100px",
      "variant-color": "primary",
      "variant": "solid",
      "loading-text": "Submitting",
      "is-disabled": _vm.$v.$invalid,
      "is-loading": _vm.isSubmitting
    }
  }, [_vm._v(" Selanjutnya ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }